<template>
  <section id="services" class="portfolio">
    <h2 class="portfolio-header">Key Services</h2>
      <div class="tile-container vertical-tile-grid">
        <!-- New tile article goes here -->
        <div class="tile">
          <img class="tile-logo" src="@/img/network-logo.png"/>
          <div class="tile-inner vertical-tile-grid"> 
            <div class="tile-text headline-text vertical-tile-grid">
              <h2 class="">Major Networks</h2>              
            </div>
            <div class="tile-text vertical-tile-grid">
              <p>
                We are completely independent and will look at all the major network providers to find you the best deal.
              </p>           
            </div>
            <a href="#contact" class="portfolio-button-cont">
              <div class="portfolio-button" >Contact Us</div>
            </a>                          
          </div>
        </div>

        <div class="tile">
          <img class="tile-logo" src="@/img/phone-logo.png"/>
          <div class="tile-inner vertical-tile-grid"> 
            <div class="tile-text headline-text vertical-tile-grid">
              <h2 class="">Handsets</h2>              
            </div>
            <div class="tile-text vertical-tile-grid">
              <p>
                We can provide you with the latest handsets from all the major providers, including Apple and Samsung.
              </p>           
            </div>
            <a href="#contact" class="portfolio-button-cont">
              <div class="portfolio-button" >Contact Us</div>
            </a>                          
          </div>
        </div>

        <div class="tile">
          <img class="tile-logo" src="@/img/wifi-logo.png"/>
          <div class="tile-inner vertical-tile-grid"> 
            <div class="tile-text headline-text vertical-tile-grid">
              <h2 class="">Broadband and Connectivity</h2>              
            </div>
            <div class="tile-text vertical-tile-grid">
              <p>
                Our broadband and connectivity products are also independent, which means we can source the best services at the most competitive prices.
              </p>           
            </div>
            <a href="#contact" class="portfolio-button-cont">
              <div class="portfolio-button" >Contact Us</div>
            </a>                          
          </div>
        </div>
        
        
      </div>
    
  </section>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
section {
  background-color: rgba(22, 36, 49, 0);
  padding: 0rem 4em;
  position: relative;
  background-size: cover;
  background-position: center;
}

.container {
  width: 90%;
  height: auto;
  padding: unset;
  margin: 20px auto;
}

.portfolio {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: auto;  

  .tile-container{
    display: flex;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    min-width: 88vw;
    max-width: 88vw;
    margin: 3rem 0rem 2rem;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-around;
    align-items: center;

    @include above-tablet-width {
      margin: 6rem 0rem 2rem;
    }

    .tile {
      background-color: #021b35;
      border-radius: 20px;
      width: 28vw;
      min-width: 28vw;
      min-height: 26rem;
      height: auto;
      max-height: 26rem;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;

      @include breakpoint-large-tablet {
        min-height: 30rem;
        height: auto;
        max-height: 30rem;
      }

      @include breakpoint-large-laptop {
        min-height: 20rem;
        height: auto;
        max-height: 20rem;
      }

      @include breakpoint-desktop {
        min-height: 20rem;
        height: auto;
        max-height: 20rem;
      }

      @include breakpoint-extra-large {
        min-height: 20rem;
        height: auto;
        max-height: 20rem;
      }

      .tile-logo {
      width: 3rem;
      background-image: url("@/img/circle-logo.png");
      width: 2rem;
      height: auto;
      position: absolute;
      top: 3.5rem;
      background-size: cover;
      background-position: center center;

        @include above-tablet-width {
          top: 6.5rem;
        }
      }
    }

    &.vertical-tile-grid {
      grid-template-columns: repeat(3, 1fr);
      min-width: 88vw;
      max-width: 88vw;
    }

    .tile-inner {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;  
      overflow: hidden;    
      cursor: pointer;
      height: 15rem;
      width: 28vw;
   
      @include breakpoint-small-mobile {
        flex-direction: column;
      }

      @include breakpoint-mobile {
        flex-direction: column;
      }

      @include breakpoint-extra-large {
        width: auto;
      }

      &.vertical-tile-grid {
        min-height: 30rem;
        height: fit-content;
        max-height: 35rem;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .tile-img-cont {
      min-width: 20rem;
      width: 20rem;
      max-width: 20rem;
      min-height: 20rem;
      height: 20rem;
      max-height: 20rem;
      overflow: hidden;
      border-radius: 15px;
    }

    .tile img{
      min-width: 4rem;
      object-fit: fill;
    }

    .tile-text {
      width: 100%;
      text-align: center;
      padding: 0.7rem;
      color: white;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-content: stretch;
      justify-content: flex-start;
      align-items: stretch;
      height: fit-content;

      &.headline-text {
        min-height: 6rem;
        height: auto;
        max-height: 6rem;
      }

      &.vertical-tile-grid {
        justify-content: flex-start;
        min-height: 6rem;
      }

      h1,h2,h3,h4,h5,h6 {
        font-size: 1.6rem;
        
      }

      h4,h5,h6 {
        font-size: 1.2rem;
      }

      h1,h2,h3,h4,h5,h6,p,ul {
        margin: 0rem 0rem 0rem 0rem !important;
        color: $main-color-hover;

        @include breakpoint-large-tablet {
          font-size: 1.4rem;
        }
      }

      p {
        display: -webkit-box;
        font-size: 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        min-height: 10rem;
        height: 10rem;
        max-height: 10rem;

        @include breakpoint-small-mobile {
          font-size: 1.1rem;
        }
        @include breakpoint-small-mobile {
          font-size: 1.1rem;
        }

        @include breakpoint-large-tablet {
          font-size: 1.4rem;
          min-height: 15rem;
          height: 15rem;
          max-height: 15rem;
        }

        @include breakpoint-laptop {
          font-size: 1.1rem;
        }

        @include breakpoint-large-laptop {
          font-size: 1rem;
          min-height: 5rem;
          height: auto;
          max-height: 10rem;
        }

        @include breakpoint-desktop {
          font-size: 1rem;
          min-height: 5rem;
          height: auto;
          max-height: 10rem;
        }

        @include breakpoint-extra-large {
          font-size: 1.2rem;
          min-height: 5rem;
          height: auto;
          max-height: 10rem;
        }

        &.vertical-tile-grid {
          -webkit-line-clamp: unset;
        }
      }
    }

    .tile:nth-child(1){
        grid-column: span 1;
        grid-row: span 1;
    }

    .tile:nth-child(2),
    .tile:nth-child(3){
        grid-column: span 1;
    }

    .tile:nth-child(4),
    .tile:nth-child(5){
        grid-column: span 1;
    }    
    .tile:nth-child(6){
        grid-column: span 1;
        grid-row: span 1;
    }
    .tile:nth-child(7),
    .tile:nth-child(8){
        grid-column: span 1;
    }
    @media screen and (max-width: 650px){
      .container{
          display: block;
      }
    }
  }
}

.portfolio-header {
  text-align: left;
  font-weight: bold;
  color: $heading-color;
  width: 60vw;
  margin: 3rem auto;
}

.portfolio-button-cont {
  margin: 1rem auto;
  display: grid;
}

.portfolio-button {
  margin: 15px auto;
  width: 12vw;
  text-align: center;
  color: #ffffff;
  background-color: $main-color;
  cursor: pointer;
  border-radius: 10px;
  padding: 15px 20px;

  @include breakpoint-small-mobile {
    width: 50vw;
  }

  @include breakpoint-mobile {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-tablet {
    width: 40vw;
    // font-size: 4vw;
  }

  @include breakpoint-large-tablet { 
    font-size: 1.4rem;
    width: 30vw;
  }

  @include breakpoint-small-laptop { 
    width: 20vw;
  }

  @include breakpoint-laptop { 
    width: 20vw;
  }

  @include breakpoint-extra-large {
    font-size: 1rem;
  }
}

.portfolio-button:hover {
  background-color: $main-color-hover;
  color: $secondary-color;
}



</style>