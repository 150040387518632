<template>
  <body
    data-spy="scroll"
    data-offset="60"
    data-target=".nav__holder"
    data-cookie="on"
    id="home"
    itemscope
    itemtype="http://schema.org/WebPage"
  >
  
  <main class="main-wrap">
      <div class="content-wrap">
        <Welcome/>     
        <Portfolio/>
        <Whyus/>
        <Reviews/>
        <ContactForm/>
        
      </div>
      
      <!-- end content wrap -->
      
      <div id="back-to-top">
        <a href="#top">
          <i class="ui-arrow-up"></i>
        </a>
      </div>
    </main>
    <!-- end main wrapper -->

  </body>
</template>
<script>
import Welcome from '@/components/Welcome.vue'
import Portfolio from '@/components/Portfolio.vue'
import Whyus from '@/components/WhyUs.vue'
import Reviews from '@/components/Reviews.vue'
import ContactForm from "@/components/ContactForm.vue";

export default{
  components: {
    Welcome,
    Portfolio,
    Whyus,
    Reviews,
    ContactForm,
  }
};

</script>

<style lang="scss">

video {
  /* override other styles to make responsive */
  width: 100%    !important;
  height: auto   !important;
}

</style>