<template>
  <div id="why" class="why-us">

    <div class="why-us-container">
      <h3 class="brand-font-colour">
        Why should you choose Complete Communications?
      </h3>  
      <h4>
        WE ALWAYS DELIVER
      </h4>   
    </div>
    
    <div class="why-us-bullet-list">
      <ul>
        <li>
          30-Day rolling business mobile contracts
        </li>
        <li>
          VoIP solutions to make remote working smarter
        </li>
        <li>
          No waiting time for customer support calls
        </li>
        <li>
          Committed to give honest advice at all times
        </li>
      </ul>
    </div>
    
    <div class="why-us-cta">
      <div class="why-us-cta-text">
        <h4>
          We are large enough to deliver the very best service, but small enough to care.
        </h4>
      </div>      
    </div>

  </div>
</template>

<script>
export default {
    mounted(){

    }
}
</script>

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";

  .why-us-cta-text {
    h4 {
      text-align: center;
      text-wrap: balance;
    }
  }
  .why-us {
    background-image: linear-gradient(to bottom, #020510bd, #07243fa6), url("@/img/branding/why-us-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 40rem;
    top: 0;
    padding: 8rem 6rem 6rem;

    @include breakpoint-small-mobile {
    }

    @include breakpoint-mobile{
    }

    @include breakpoint-tablet{
    }

    @include breakpoint-large-tablet {
    }

    @include breakpoint-small-laptop{
    }

    @include breakpoint-laptop{
    }  

    @include breakpoint-large-laptop{
    }

    @include breakpoint-desktop{
    }

    @include breakpoint-extra-large {
    }

    img {
      display: block;
      position: relative;
      width: 100vw;
    }

    .why-us-container {
      margin: 1rem auto 1rem;
      width: 45rem;
      text-wrap: wrap;
      text-align: center;
    }

    .why-us-bullet-list {
      color: $tertiary-color;
      margin: 1rem auto;
    }

    .why-us-cta {
      margin:2rem auto;
    }
  }

</style>