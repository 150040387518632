<template>
<!-- Header -->
  <header
    class="nav nav--always-fixed"
    id="reduced"
    itemtype="http://schema.org/WPHeader"
    itemscope
  >
    <div class="nav__holder nav--align-center">
      <div class="container-fluid container-semi-fluid">
        <div class="flex-parent">
          <div class="nav__header clearfix">
            <!-- Logo -->
            <div class="logo-wrap local-scroll">
              <router-link to="/" class="nav-link">
                <img
                  class="nav-logo"
                  src="@/img/branding/logo-horizontal.png"
                  srcset="@/img/branding/logo-horizontal.png"
                  alt="business logo"
                  itemtype="http://schema.org/Organization"
                  itemscope
                />
              </router-link>
            </div>

            <button
              type="button"
              class="nav__icon-toggle"
              id="nav__icon-toggle"
              data-toggle="collapse"
              data-target="#navbar-collapse"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
              <span class="nav__icon-toggle-bar"></span>
            </button>
          </div>
          <!-- end nav-header -->

          <!-- Navbar -->
          <nav
            id="navbar-collapse"
            class="nav__wrap collapse navbar-collapse"
            itemtype="http://schema.org/SiteNavigationElement"
            itemscope="itemscope"
          >

            <ul class="nav__menu local-scroll" id="onepage-nav">

              <!-- Services -->
              <li>
                <a href="#services">Services</a>
              </li>

              <!-- Why us -->
              <li>
                <a href="#why">Why Us</a>
              </li>

              <!-- Reviews -->
              <li>
                <a href="#reviews">Reviews</a>
              </li>

              <!-- Contact us -->
              <li>
                <a href="#contact">Contact Us</a>
              </li> 

            </ul>

          </nav>
          <!-- end nav-wrap -->

          <!-- Socials -->
          <div class="nav__socials flex-child d-none d-lg-block">
            <div class="socials right">

              <a class="social text-center" href="tel:03333200521">
                <img class="social-logo"
                  src="@/img/mobile-logo.png"
                />
              </a>

              <a class="social text-center" href="mailto:nick@complete-communications.co.uk" target="_blank">
                <img class="social-logo"
                  src="@/img/mail-logo.png"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- end flex-parent -->
      </div>
      <!-- end container -->
    </div>
  </header>
  <!-- end navigation -->

</template>

<script>
import $ from 'jquery'
export default {
  data() {
    return{
         activeClass: 'ui-arrow-down'
    }
  },
  methods:{
      toggledroparrow(){
        
        if(this.activeClass == 'ui-arrow-down')
        {
          this.activeClass = 'ui-arrow-up'
        }
        else
        {
          this.activeClass = 'ui-arrow-down'
        }
      }
  },
  mounted: function(){

  }
};
</script>

<style lang="scss">
.social-logo {
  width: 3rem;
  background-image: url("@/img/circle-logo.png");
  width: 2rem; height: 100%;
  background-size: cover;
  background-position: center center;
}

.social-logo:hover {
  filter: brightness(0.8);
}
</style>