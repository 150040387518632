<template>
  <div class="welcome">

    <div class="welcome-container">
      <h4>
        MOBILES AND TARIFFS FOR YOUR BUSINESS
      </h4>   
    </div>
    
    <div class="welcome-side-by-side">
      <div class="welcome-text-cont">
        <h5>
          We work with the key network providers, including O2, Vodafone, EE and Three to find you the best deal for you and your business.
        </h5>
      </div>

      <div class="network-logo-cont">
        <img class="network-logo" src="@/img/services/O2_white.png">
        <img class="network-logo" src="@/img/services/Vodafone_white.png">
        <img class="network-logo" src="@/img/services/EE_white.png">
        <img class="network-logo" src="@/img/services/three-network-logo.png">
      </div>
    </div>
    
    <div class="welcome-cta">
      <div class="welcome-cta-text">
        <h6>
          Get in touch today to discuss your requirements with a FREE audit.
        </h6>
      </div>
      <div class="cta-button-cont">
        <a href="#contact" class="portfolio-button-cont">
          <div class="portfolio-button" >Click Here</div>
        </a>
      </div>
    </div>

  </div>
</template>


<script>
export default {
    mounted(){

    }
}
</script>


<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";
  .welcome {
    background-image: linear-gradient(to bottom, #020510bd, #07243fa6), url("../img/branding/man-using-digital-tablet.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 40rem;
    top: 0;
    padding: 8rem 6rem 6rem;

    @include breakpoint-small-mobile {
    }

    @include breakpoint-mobile{
    }

    @include breakpoint-tablet{
    }

    @include breakpoint-large-tablet {
    }

    @include breakpoint-small-laptop{
    }

    @include breakpoint-laptop{
    }  

    @include breakpoint-large-laptop{
    }

    @include breakpoint-desktop{
    }

    @include breakpoint-extra-large {
    }

    .welcome-container {
      margin: 1rem auto 1rem;
      width: 35rem;
      text-wrap: wrap;
      text-align: center;
    }

    .welcome-side-by-side {
      display: flex;
      flex-direction: row;
      margin: 2rem auto 1rem;
      width: 80vw;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      div {
        margin: 0rem 2vw;
      }

      .welcome-text-cont {
        width: 20vw;

        h1,h2,h3,h4,h5,h6,p {
          font-weight: 400;
        }
      }
    }
  }

  .network-logo-cont {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    .network-logo {
      width: 8rem;
    }
  }

  .welcome-cta {

    .welcome-cta-text {

    }

    .cta-button-cont {

    }

  }

  
</style>