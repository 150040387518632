import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Privacy from "../views/Privacy.vue";
//Import all other pages to route here

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/Privacy',
        name: 'Privacy',
        component: Privacy
    },

    // Add new routes here
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition){
        return{top: 0};
    },
    routes
})

export default router
