<template>
  <section id="reviews" class="review-section">
    <h2 class="review-header">Reviews</h2>

    <div class="page-carousel">
      <div class="slider">
          <div class="slide-track">
              <div class="slide">
                  <p>
                    “To anyone trying to manage a mobile phone estate. Whether it’s running smoothly or out of control you need to look up Complete ICT.
                    <br>
                    Dealing with any of the networks’ Customer Services Teams can at times be a frustrating experience. Complete ICT cut through the call centre barrier. They know how to talk to these people, they know the tricks of the tread and importantly they know how to make them work for you, rather than against you.  They know the platforms, they know the reports, enabling them to provide meaningful accurate information that can be acted upon confidence. Information that will save us an estimated £230,000 throughout the contract duration.”
                  </p>
                  <h4>
                    Ian Jones
                  </h4>
                  <h6>
                    IT and Systems Director, PAM Group
                  </h6>
              </div>
              <div class="slide">
                <p>
                  “The work that Complete Communications undertook gives us confidence that our mobile account no longer has any cost or service inefficiencies. The savings achieved once the project was complete were higher than first estimated and we have reduced our Vodafone charges by 60% and our EE charges by 27%.
                  <br>
                  Appreciate your work on this project and look forward to continuing to work with you in the future.”
                </p>
                <h4>
                  Steve Barker
                </h4>
                <h6>
                  Commercial Director - Dyson Farming Ltd
                </h6>
              </div>
              <div class="slide">
                  <p>
                    “To anyone trying to manage a mobile phone estate. Whether it’s running smoothly or out of control you need to look up Complete ICT.
                    <br>
                    Dealing with any of the networks’ Customer Services Teams can at times be a frustrating experience. Complete ICT cut through the call centre barrier. They know how to talk to these people, they know the tricks of the tread and importantly they know how to make them work for you, rather than against you.  They know the platforms, they know the reports, enabling them to provide meaningful accurate information that can be acted upon confidence. Information that will save us an estimated £230,000 throughout the contract duration.”
                  </p>
                  <h4>
                    Ian Jones
                  </h4>
                  <h6>
                    IT and Systems Director, PAM Group
                  </h6>
              </div>
              <div class="slide">
                <p>
                  “The work that Complete Communications undertook gives us confidence that our mobile account no longer has any cost or service inefficiencies. The savings achieved once the project was complete were higher than first estimated and we have reduced our Vodafone charges by 60% and our EE charges by 27%.
                  <br>
                  Appreciate your work on this project and look forward to continuing to work with you in the future.”
                </p>
                <h4>
                  Steve Barker
                </h4>
                <h6>
                  Commercial Director - Dyson Farming Ltd
                </h6>
              </div>         
            </div>
        </div>
    </div>    
    
  </section>
  <div class="tile-container">
      <div class="masonry">
        <div class="tile">
          <span>
            <img src="@/img/clients/riso-logo.jpg" alt="riso logo">
          </span>
        </div> 
        <div class="tile">
          <span>
            <img src="@/img/clients/PAM-logo.jpg" alt="pam group logo">
          </span>
        </div> 
        <div class="tile">
          <span>
            <img src="@/img/clients/alexander-dennis-logo.jpg" alt="alexander dennis logo">
          </span>
        </div> 
        <div class="tile">
          <span>
            <img src="@/img/clients/dyson-farming-logo.jpg" alt="Dyson farming logo">
          </span>
        </div>
        <div class="tile">
          <span>
            <img src="@/img/clients/cabns-logo.jpg" alt="citizens advice north staffordshire">
          </span>
        </div>        
      </div>
    </div>
</template>

<script>
export default {
};
</script>

  

<style lang="scss">
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";

.review-section {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  flex-direction: column;

  .review-header {
    text-align: left;
    font-weight: bold;
    color: #131313;
    width: 60vw;
    margin: 3rem auto;
  }
}

.slide-track {
  width: 80vw;
  height: 25rem;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
}

.slide-track .slide{ 
  position:absolute; 
  animation:fling-minislide 40s infinite; 
  opacity:0; 
  width: 100%; 
  height: auto;
  h1,h2,h3,h4,h5,h6,p {
    color: #000000;
    text-align: left;
  }
}

@keyframes fling-minislide {
  25%{opacity:1;} 
  30%{opacity:0;}
}

.slide-track :nth-child(4){animation-delay:0s;}
.slide-track :nth-child(3){animation-delay:10s;}
.slide-track :nth-child(2){animation-delay:20s;}
.slide-track :nth-child(1){animation-delay:30s;}


// Slider Styling
.slider {
  background: rgb(255 255 255);
  height: 30rem;
  padding-top: 2rem;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100rem;

  &::before,
  &::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  &::before {
    left: 0;
    top: 0;
  }


  .slide {
    height: inherit;
    width: 80vw;

    p {
      @include breakpoint-large-tablet  {
        font-size: 1.2rem;
      }
    }
    
  }
}


// Caroulsel Styling

.page-carousel {
    padding: 2rem 3rem;
    margin-top: 0rem;
    width: 100%;
    text-align: center;

    h1 {
        font-size: 2.8rem;
        color: white;
        text-align: center;
    }

    .carousel-images {
        display: flex;
        justify-content: space-between;
        padding: 1rem;


        img {
            width: 20%;
            border-radius: 20px;
            box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.5);
        }
    }

    ul.slides {
        display: block;
        position: relative;
        height: 600px;
        margin: 0;
        padding: 0;
        overflow: hidden;
        list-style: none;
    }

    .slides * {
        user-select: none;
        -ms-user-select: none;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -webkit-touch-callout: none;
    }

    ul.slides input {
        display: none;
    }


    .slide-container {
        display: block;
    }

    .slide-image {
        position: absolute;
        bottom: 12rem;
        display: flex;
        justify-content: space-between;
        padding: 1rem;
        opacity: 0;
        transition: all .7s ease-in-out;
    }

    .slide-image img {
        height: 19.8rem;
        max-width: 39.2rem;
        border-radius: 20px;
        box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.5);
        margin-right: 1rem;
    }

    .carousel-controls {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 999;
        font-size: 100px;
        line-height: 600px;
        color: #fff;
    }

    .carousel-controls label {
        display: none;
        position: absolute;
        padding: 0 20px;
        opacity: 0;
        transition: opacity .2s;
        cursor: pointer;
    }

    .slide-image:hover + .carousel-controls label {
        opacity: 0.5;
    }

    .carousel-controls label:hover {
        opacity: 1;
    }

    .carousel-controls .prev-slide {
        width: 49%;
        text-align: left;
        left: 0;
    }

    .carousel-controls .next-slide {
        width: 49%;
        text-align: right;
        right: 0;
    }

    .carousel-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        z-index: 999;
        text-align: center;
    }

    .carousel-dots .carousel-dot {
        display: inline-block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        opacity: 0.5;
        margin: 10px;
    }

    input:checked + .slide-container .slide-image {
        opacity: 1;
        transform: scale(1);
        transition: opacity 1s ease-in-out;
    }

    input:checked + .slide-container .carousel-controls label {
        display: block;
    }

    input#img-1:checked ~ .carousel-dots label#img-dot-1,
    input#img-2:checked ~ .carousel-dots label#img-dot-2,
    input#img-3:checked ~ .carousel-dots label#img-dot-3,
    input#img-4:checked ~ .carousel-dots label#img-dot-4,
    input#img-5:checked ~ .carousel-dots label#img-dot-5,
    input#img-6:checked ~ .carousel-dots label#img-dot-6 {
        opacity: 1;
    }


    input:checked + .slide-container .nav label {
        display: block;
    }
}

.carousel-cont {
  position: relative;
  width: 60vw;
  height: 32rem;
  margin: 4rem auto;
}

.carousel-cont > div {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: display 15s infinite;
}

.carousel-slide {
  h1,h2,h3,h4,h5,h6,p {
    color: $secondary-color;
  }
}

div:nth-child(2) {
  animation-delay: 6s;
}

.tile-container {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  .masonry {
    -moz-columns: 5;
    -moz-column-count: 5;
    column-count: 5;
    -moz-column-gap: 0;
    column-gap: 0;

    .tile {
      color: #fff;
      font-size: 3em;
      font-weight: 700;
      height: auto;
      line-height: 150px;
      overflow: hidden;
      position: relative;
      text-align: center;
      transition: background-color .5s ease, transform .5s ease;
      width: auto;
    }
  }
}
</style>