<template>
  <div>
  <!-- Footer -->
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope
    >
      <!-- 1st Footer Row -->
      <div class="">
        <div class="footer__widgets">
          <div class="footer-contact-grid-container">
            <!-- 1st Column contact -->
            <div class="col-lg-4 col-md-6">
              <div class="footer-element-cont">                
                <div class="footer-element">
                  <span class="copyright text-left">
                    <router-link to="Privacy" class="copyright">
                      <span>Privacy Policy</span>
                    </router-link>
                  </span>
                </div>
                <div class="footer-element">
                  <p>©2024 Complete Communications. All rights reserved.</p>
                </div>
                <div class="footer-element">
                  <a class="col-lg-4 col-md-6 text-center" href="https://www.cyberkiln.com" target="_blank">
                    <img class="ck-logo"
                      src="@/img/branding/header-logo.svg"
                      alt="CyberKiln Logo"
                    />
                    <div class="text-center">
                      <h3 class="widget-cta__title white2 ck-text">
                        Crafting your digital world
                      </h3>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      
    </footer>
    <!-- end footer -->
    <!-- <div class="footer-placeholder" id="contact"></div> -->
  </div>
</template>

<script>

export default {
  name: "app",
  components: {
  },
  data() {
    return {
    };
  },
  methods: {
  }
};
</script>

<style lang="scss"> 
@import '../styles/base/_mixins.scss';
@import "../styles/base/_variables.scss";

.footer {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0vw;
  height: -webkit-fill-available;
  height: -moz-fill-available;
  height: -fill-available;
  background-color: $footer-bg-colour;
}

.footer-element-cont {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.footer-element {
  text-align: center;
  margin: 1rem auto;
  color: $footer-text-colour;
}

.footer-left-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  left: 0;
  height: 50vw;
  z-index: -3;
}

.footer-right-kiln {
  display: block;
  position: absolute;
  bottom: 8vw;
  right: 0;
  height: 50vw;
  z-index: -3;
}

.ck-logo {
  width: 20vw;
  height: auto;
}

.ck-logo:hover {
  filter: drop-shadow(0px 0px 5px #ffffff);
}

.ck-text:hover {
  text-shadow: 2px 2px 13px white;
}

.footer-contact-grid-container{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;

  @include breakpoint-large-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

  @include breakpoint-tablet{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0 !important;
    margin-left: 0 !important;
    flex-direction: column;
    align-content: center;
  }

}

.widget-about-us__text {
  a {
    margin: 1rem auto;
  }
}

.networking-grid-container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 30em;
  grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  grid-gap: 0.5em;

  @include breakpoint-large-tablet{
    column-count: 2 !important;
    display: table-row !important;
  }

  @include breakpoint-tablet{
    column-count: 2 !important;
    display: table-row !important;
  }

  @include breakpoint-mobile {
    column-count: 2 !important;
    display: table-row !important;
  }
}

.footer-logo-container {
  flex: 1 0 40%;
  max-width: 100%;
  @include breakpoint-small-mobile {
    flex: 1 1 40%;
    padding: 1.5vw;
  }
  @include breakpoint-mobile {
    flex: 1 1 20%;
    padding: 1.5vw;
  }
  @include breakpoint-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }
  @include breakpoint-large-tablet {
    flex: 1 1 10%;
    padding: 1em;
  }

  @include breakpoint-small-laptop  {
    flex: 1 0 100%;
  }
}

.footer-logo {
  width: 20vw;
  cursor: pointer;
  height: auto;

  @include breakpoint-small-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-mobile {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-large-tablet {
    padding: 0.4em;
    width: 24vw;
  }
  @include breakpoint-small-laptop  {
    width: 10rem;
  }
}

.footer__bottom {
  z-index: 5;
  padding-bottom: 8em;
  margin-top: 2vw;

  @include breakpoint-small-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }
  @include breakpoint-mobile {
    padding-bottom: 0;
    margin-top: 0;
  }

  @include breakpoint-tablet {
    padding-bottom: 2em;
  }

  @include breakpoint-large-tablet {
    padding-bottom: 0;
    margin-top: 0;
  }
}

</style>